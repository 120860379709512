import React from "react";
import Layout from "../components/layout";
import aboutStyles from "./about.module.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { graphql, useStaticQuery } from "gatsby";
import Content from "../components/content";

const AboutPage = () => {

    const data = useStaticQuery (graphql`
    query {
        contentfulAbout{
            bio{
                raw
            }
            image {
                file {
                    url
                }
                title
            }
        }
    }
    `)
    //const image = getImage(data.contentfulAbout.image.file)
    //console.log(image);

    return (
        <Layout>
            <Content>
                <h1 className={aboutStyles.title}>About me</h1>
                <div className={aboutStyles.container}>
                    <div className={aboutStyles.item}>
                        <div className={aboutStyles.bio}>
                            <p>{renderRichText(data.contentfulAbout.bio)}</p>
                        </div>
                    </div>
                    <div className={aboutStyles.item}>
                        <img className={aboutStyles.profilePhoto} src={data.contentfulAbout.image.file.url} alt={data.contentfulAbout.image.title} />
                    </div>
                </div>
            </Content>
        </Layout>
    )
};

export default AboutPage;